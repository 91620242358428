import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Button,
  Collapse,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import { createElement } from "react";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 12,
    margin: "auto",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: 15,
  },
}));

const BabynamesComponent = () => {
  const classes = useStyles();
  const xsSize = 12;
  const smSize = 4;
  const lgSize = 3;
  var imageUrl = "../images/babynames.png";
  useEffect(() => {
    document.title = "Vala Barnanöfn";

    // var elem = document.getElementsByTagName("head")[0]

    document.head.innerHTML += `
        <head>
        <title>Barnanöfn</title>
        <meta
          name="description"
          content="Vala barnanöfn er app sem hjálpar notendum að finna nöfn á verðandi börnin sín"
        />
        <meta
          name="keywords"
          content="Barnanöfn, Barnanöfn, Krílið, Nefna, Vala Barnanöfn"
        />
      </head>
        `;
  }, []);

  return (
    <>
      <head>
        <title>Barnanöfn</title>
        <meta
          name="description"
          content="Vala barnanöfn er app sem hjálpar notendum að finna nöfn á verðandi börnin sín"
        />
        <meta
          name="keywords"
          content="Barnanöfn, Barnanöfn, Krílið, Nefna, Vala Barnanöfn"
        />
      </head>

      <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>
          <h1>Vala Barnanöfn</h1>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent style={{ padding: 0 }}>
              <CardMedia
                style={{ height: 400, width: "auto" }}
                image={imageUrl}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <p>Vala barnanöfn hjálpar þér að finna nafn á krílið.</p>
          <p>Finnst á AppStore og Google Play.</p>
        </Grid>
      </Grid>
    </>
  );
};

export default BabynamesComponent;
